<template>
  <v-row
    :class="
      this.$vuetify.breakpoint.name == 'xs' ||
      this.$vuetify.breakpoint.name == 'sm'
        ? 'px-0'
        : 'px-2'
    "
  >
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs
        class="pl-0 primary--text"
        :items="navigator"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col class="pb-1" cols="12">
      <strong class="primary--text devTitleType">RegeX Generator</strong>
    </v-col>

    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="60vh">
        <v-card-title>Input RegeX Pattern</v-card-title>
        <v-card-text>
          <v-textarea
            background-color="#111111"
            v-model="regexPattern"
            rows="5"
            outlined
            placeholder="Enter regex pattern directly like [A-Z] "
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="60vh">
        <v-card-title>Input Test String</v-card-title>
        <v-card-text>
          <v-textarea
            background-color="#111111"
            v-model="testString"
            rows="5"
            outlined
            placeholder="Enter the string to test the regex pattern"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="buttonPosition">
      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        :disabled="testString && regexPattern ? false : true"
        color="primary"
        class="btnPad px-2"
        @click="regexTest"
        >Test RegeX</v-btn
      >

      <v-btn
        :small="this.$vuetify.breakpoint.name == 'xs' ? true : false"
        outlined
        color="primary"
        class="btnPad"
        @click="clearText"
        >Reset</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card min-height="100%" height="max-content" max-height="70vh">
        <v-card-title>Output</v-card-title>
        <v-card-text ref="output">
          <!-- <v-textarea
            background-color="#111111"
            v-model="matchedString"
            rows="10"
            ref="output"
            outlined
          ></v-textarea> -->
          <div class="output-body" id="match-result">
            <div id="output-string"></div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card tile>
        <v-card-title class="primary--text devTitleType"
          >What is RegeX Generator ?</v-card-title
        >
        <v-card-text class="devTextType"
          ><p>
            A <strong class="primary--text">regex generator</strong> is a tool
            or software that helps in creating regular expressions (regex)
            automatically based on certain criteria or patterns. Regular
            expressions are powerful patterns used to match and manipulate text.
            However, they can be complex and require careful construction.
          </p>

          <p>
            A regex generator simplifies the process of creating regular
            expressions by providing a user-friendly interface or set of rules
            to define the desired pattern. It allows users to input specific
            requirements, such as matching certain characters, patterns, or
            constraints, and generates the corresponding regular expression
            code.
          </p>

          <strong class="mt-3 ml-3"
            >The following characters are used to generator patterns for regex
            which in later can be used to manipulate a string:</strong
          >
          <v-data-table
            :headers="headers"
            :items="cheatSheet"
            :search="search"
            class="elevation-1 cheat-sheet-table ma-4"
          >
            <template v-slot:item.pattern="{ item }">
              <strong class="primary--text">{{ item.pattern }}</strong>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { scrollToTop } from "@/helpers/helperFunctions.js";

export default {
  data() {
    return {
      navigator: [
        {
          text: "Developer Tools",
          disabled: false,
          href: "",
        },
        {
          text: "Formatter",
          disabled: false,
          href: "",
        },
        {
          text: "regex-generator",
          disabled: true,
          href: "regex",
        },
      ],
      regexPattern: "",
      matchedString: "",
      testString: "",
      search: "",
      headers: [
        { text: "Pattern", value: "pattern", align: "center" },
        { text: "Description", value: "description" },
      ],
      cheatSheet: [
        {
          pattern: "abc",
          description: "Matches the characters 'abc' exactly.",
        },
        {
          pattern: "[abc]",
          description:
            "Matches any single character in the set 'a', 'b', or 'c'.",
        },
        {
          pattern: "[^abc]",
          description: "Matches any single character except 'a', 'b', or 'c'.",
        },
        {
          pattern: "[a-z]",
          description: "Matches any single lowercase letter.",
        },
        {
          pattern: "[A-Z]",
          description: "Matches any single uppercase letter.",
        },
        { pattern: "[0-9]", description: "Matches any single digit." },
        {
          pattern: "[a-zA-Z0-9]",
          description: "Matches any single alphanumeric character.",
        },
        { pattern: "*", description: "Matches zero or more occurrences." },
        { pattern: "+", description: "Matches one or more occurrences." },
        { pattern: "?", description: "Matches zero or one occurrence." },
        { pattern: "{n}", description: "Matches exactly 'n' occurrences." },
        { pattern: "{n,}", description: "Matches 'n' or more occurrences." },
        {
          pattern: "{n,m}",
          description: "Matches between 'n' and 'm' occurrences.",
        },
        {
          pattern: "^",
          description: "Matches the beginning of a line/string.",
        },
        { pattern: "$", description: "Matches the end of a line/string." },
        { pattern: "\\b", description: "Matches a word boundary." },
        {
          pattern: ".",
          description: "Matches any single character except a newline.",
        },
        { pattern: "\\", description: "Escapes a special character." },
        {
          pattern: "|",
          description: "Matches either the pattern before or after the pipe.",
        },
        { pattern: "()", description: "Groups patterns together." },
        { pattern: "i", description: "Performs case-insensitive matching." },
        {
          pattern: "g",
          description: "Performs global matching (find all matches).",
        },
        { pattern: "m", description: "Performs multiline matching." },
      ],
    };
  },
  methods: {
    regexTest() {
      const regex = new RegExp(this.regexPattern, "g");
      const matches = this.testString.match(regex);
      const matchResultDiv = document.getElementById("match-result");

      if (matches) {
        const matchCount = matches.length;
        const matchResult = `Found ${matchCount} match${
          matchCount === 1 ? "" : "es"
        }.`;
        matchResultDiv.textContent = matchResult;
        // Print the output with highlighted matches
        const output = this.testString.replace(
          regex,
          '<span class="highlight-text">$&</span>'
        );
        matchResultDiv.innerHTML += "<br><br> <span> Result: <br>" + output;
        this.scrollOutputContainer();
      } else {
        matchResultDiv.textContent = "No matches found.";
      }
    },
    clearText() {
      this.regexPattern = "";
      this.testString = "";
      this.matchedString = "";
      const outputElement = document.getElementById("match-result");
      outputElement.textContent = "";
    },
    scrollOutputContainer() {
      const outputElement = document.getElementById("match-result");
      outputElement.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    scrollToTop();
  },
};
</script>
<style scopped>
.buttonPosition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.cheat-sheet-table {
  border: 1px solid #fff;
}

.btnPad {
  margin-bottom: 20px;
}

.output-body {
  background-color: #111111;
  min-height: 200px;
  height: max-content;
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  padding: 20px;
  border: 1px solid white;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
}
#output-string {
  margin-top: 20px;
}
.highlight-text {
  background-color: #ff6700;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 1260px) {
  .buttonPosition {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btnPad {
    margin-right: 20px;
  }
}
</style>
